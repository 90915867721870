<template>
  <div>
    <div class="view pa24">
      <el-row>
        <el-col :xl="6">
          <div class="d-flex flex-column align-items-center">
            <h2 style="color: #fd634e">￥{{ amountRes.sumBalance | formatAmount }}</h2>
            <span>累计发放佣金</span>
          </div>
        </el-col>
        <el-col :xl="6">
          <div class="d-flex flex-column align-items-center">
            <h2 style="color: #fd634e">￥{{ amountRes.withdrawBalance | formatAmount }}</h2>
            <span>已提现金额</span>
          </div>
        </el-col>
        <el-col :xl="6">
          <div class="d-flex flex-column align-items-center">
            <h2 style="color: #fd634e">{{ amountRes.waitOrderCount || 0 }}</h2>
            <span>待收货订单</span>
          </div>
        </el-col>
        <el-col :xl="6">
          <div class="d-flex flex-column align-items-center">
            <h2 style="color: #fd634e">￥{{ amountRes.waitAmount | formatAmount }}</h2>
            <span>待到账金额</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-col :xl="12">
      <el-card shadow="never" style="border: 0" class="mt20">
        <div class="d-flex align-items-center">
          <span style="color: #51cbcd">佣金支出统计</span>
          <el-date-picker v-model="effectiveDate" class="ml20" align="right" type="daterange" value-format="yyyy-MM-dd"
            start-placeholder="开始日期" end-placeholder="结束日期" />
          <el-button class="ml10" type="primary" @click="expenditureCount">搜索</el-button>
        </div>
        <div class="pt20 bgfff" id="container"></div>
      </el-card>
    </el-col>
    <el-col :xl="12">
      <el-card shadow="never" style="border: 0" class="mt20">
        <div class="d-flex align-items-center">
          <span style="color: #51cbcd">分销商品占比</span>
        </div>
        <div id="main" style="width: 100%; height: 400px"></div>
        <!-- <div id="roomTypeCheckIn"></div> -->
      </el-card>
    </el-col>
    <el-col :xl="24">
      <distributionGoods />
    </el-col>
    <el-col :xl="24" style="padding-bottom: 24px">
      <distributionUsers />
    </el-col>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import * as echarts from 'echarts';
import {
  commissionCount,
  goodRankPercentage,
  expenditureCount,
} from "@/api/distribution";
import distributionUsers from "./components/distributionUsers";
import distributionGoods from "./components/distributionGoods";
import { get } from "@/utils/request";
import good from "@/mixins/good";
export default {
  data() {
    return {
      sumBalance: 0,
      sumWithdrawBalance: 0,
      orderNum: 0,
      commissionAmount: 0,
      effectiveDate: [],
      data: [{ type: "销售额", value: 0 }],
      mydata: [],
      mychart: '',
      amountRes: {},
      dataList: [],
      total: 0
    };
  },
  mixins: [good],
  components: {
    distributionUsers,
    distributionGoods,
  },
  created() {
    this.commissionCount();
    this.goodRankPercentage();
    this.expenditureCount();

  },
  mounted() {
    // this.initComponentTow()
  },
  methods: {
    initComponent() {
      const chart = new Chart({
        container: "roomTypeCheckIn",
        autoFit: true,
        height: 410,
        width: 400,
      });
      chart.data(this.data);
      chart.legend(false);
      chart.tooltip({
        showMarkers: false,
      });
      chart.facet("rect", {
        fields: ["type"],
        padding: 20,
        showTitle: false,
        eachView: (view, facet) => {
          const data = facet.data;
          let color = "#0a9afe";
          data.push({ type: "其他", value: 100 - data[0].value });
          view.data(data);
          view.coordinate("theta", {
            radius: 0.8,
            innerRadius: 0.5,
          });
          view
            .interval()
            .adjust("stack")
            .position("value")
            .color("type", [color, "#eceef1"])
            .style({
              opacity: 1,
            });
          view.annotation().text({
            position: ["50%", "50%"],
            content: data[0].type,
            style: {
              fontSize: 12,
              fill: "#8c8c8c",
              fontWeight: 300,
              textBaseline: "bottom",
              textAlign: "center",
            },
            offsetY: -12,
          });

          view.annotation().text({
            position: ["50%", "50%"],
            content: data[0].value ? (data[0].value / 100).toFixed(2) : 0,
            style: {
              fontSize: 18,
              fill: "#000",
              fontWeight: 500,
              textAlign: "center",
            },
            offsetY: 10,
          });

          view.interaction("element-active");
        },
      });
      chart.render();
    },
    initComponentTow() {
      let myChart = echarts.init(document.getElementById('main'));
      var option = {
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            data: this.dataList,
            label: {
              show: true,
              position: 'center',
              normal: {
                color: '#595D66',
                formatter: '{b}\n￥{c}',
                fontSize: '14',
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ],
        graphic: { // 添加原生图形元素组件
          elements: [{
            type: 'text', // 组件类型
            left: 'center', //定位
            top: '43%', // 定位
            style: { // 样式
              text: '销售额', //文字
              fontSize: 16, //文字大小
              textAlign: 'center', //定位
              width: 30,
              height: 30,
              fill: 'darkgray' // 字体颜色
            }
          },
          {
            type: 'text',
            left: 'center',
            top: '52%',
            style: {
              text: '￥'+(this.total / 100).toFixed(2),
              fontSize: 18,
              textAlign: 'center',
              width: 30,
              height: 25,
              fill: 'darkgray'
            }
          }]
        }
      };
      myChart.setOption(option);
    },
    async commissionCount() {
      try {
        get('/distributor/commissionCount').then(res => {
          this.amountRes = res;
        })
        // const result = await commissionCount({ xx: 0 }, { showLoading: true });
        // const {
        //   data: { commissionAmount, orderNum, sumBalance, sumWithdrawBalance },
        // } = result;
        // this.sumBalance = sumBalance;
        // this.sumWithdrawBalance = sumWithdrawBalance;
        // this.orderNum = orderNum;
        // this.commissionAmount = commissionAmount;
      } catch (error) {
        console.log(error);
      }
    },
    async goodRankPercentage() {
      try {
        const result = await goodRankPercentage({ xx: 0 });
        console.log('---------------' + JSON.stringify(result))
        var list = result.data;
        list.map((item, index) => {
          this.total += item.price
          this.$set(this.dataList, [index], { 'value': (item.price / 100).toFixed(2), 'name': item.goodsName })
        })
        console.log('---------------' + JSON.stringify(this.dataList))
        this.initComponentTow()
        this.initComponent();
      } catch (error) {
        console.log(error);
      }
    },
    async expenditureCount() {
      let data = {
        typeTime: (!this.effectiveDate || this.effectiveDate.length == 0) ? 'year' : '',
        startTime: this.effectiveDate && this.effectiveDate[0],
        endTime: this.effectiveDate && this.effectiveDate[1],
      };
      const result = await expenditureCount(data);
      this.mydata = result.data ? result.data : []
      // this.mychart.changeData(this.mydata)
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
